import {useEffect} from 'react';
import h337 from "heatmap.js";
import './HeatmapChart.scss';
import { useTranslation } from 'react-i18next';
import PropTypes from "prop-types";
import {transformLinearVector} from "../../utils/LinearCalcHelper";
import {envMap} from '../../utils/utils'


const height = 300
const transformationDimensions = {height:710,width:1380}
const layoutDimensions = {height:height, width:height*transformationDimensions.width/transformationDimensions.height}
const adjustmentHeight = 0;
const adjustmentWidth = 0;
const url = process.env.REACT_APP_IMAGE_STORAGE_PATH + 'pools/' + envMap[ process.env.NODE_ENV]+'/'

const HeatmapChart = props => {
    const [t] = useTranslation();
    const colors = ['#F0F9FF', '#B7DAEF', '#57A6DB', '#174B6D'];
    const scale ={x:(layoutDimensions.width)/transformationDimensions.width,y:(layoutDimensions.height)/transformationDimensions.height}
    const swimmersLocationsTransformed = props.data?.data?.map(l => {
        const point  = transformLinearVector(props.transformation,l)

        const newPoint = {x: adjustmentWidth+(scale.x * point.x), y: adjustmentHeight+(scale.y*point.y), value:l.value}
        return {x:Math.round(newPoint.x), y: Math.round(newPoint.y), value: l.value}
    } ) || []

    useEffect(() => {
        const heatmapInstance = h337.create({
          container: document.getElementById('heatmap-container'),
            width:layoutDimensions.width-20,
            height:280,
          gradient: {0: '#F0F9FF', 0.33: '#B7DAEF', 0.66: '#57A6DB', 1: '#174B6D'}
        });
        heatmapInstance.setData({max:props.data.max, data:swimmersLocationsTransformed});
    }, []);
    const handleImageError = () => {
        const img = document.getElementById('pool-image')
        img.src = url + `prod/pool_id_0.svg`
    }

  return (
    <div id={'heatmap-page'}>
        <div className={'top-container'}>
            <div className={'tile-title'}>{t('heatmap')}</div>
          <div className={'gradient-container'}>
              <div className={'plain-text'}>{t('low')}</div>
              <div className={'color-tape'}>
                  {colors.map((color, index) => {
                      return (
                          <div key={index} className={'square'} style={{ background: color }}></div>
                      );
                  })}
              </div>
              <div className={'plain-text'}>{t('high')}</div>
          </div>
        </div>
        <div className={'pool-image-container'}>
            <div id={'heatmap-container'} className={'swimmers-layout'}/>
            <img id={'pool-image'} className={'img-pool'} src={url+'pool_id_'+props.poolId+'.svg'} onError={handleImageError} alt="Pool-Image"/>
        </div>
    </div>
  );
};
HeatmapChart.propTypes = {
    data: PropTypes.object.isRequired,
    transformation: PropTypes.array.isRequired,
    poolId: PropTypes.number.isRequired
}

export default HeatmapChart;