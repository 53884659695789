import './LifeguardAccordion.scss'
import Button from '@mui/material/Button';
import "react-international-phone/style.css";
import * as React from 'react';
import {useState} from 'react';
import {Accordion, AccordionActions, AccordionDetails, AccordionSummary, Modal, styled, TextField} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import PropTypes from "prop-types";
import * as EmailValidator from "email-validator";
import parsePhoneNumber from "libphonenumber-js";
import {createBulkLifeguardViaExcel, createLifeguard} from "../../../../api/api";
import {clearSpaces, getCountryPrefix, getNodeContext, getOrgCountry} from "../../../../utils/utils";
import RemoveIcon from '@mui/icons-material/Remove';
import CustomPhoneInput from "../../../CustomPhoneInput/CustomPhoneInput";
import {showSnackbar} from "../../../../utils/SnackbarUtils";
import {useTranslation} from "react-i18next";
import * as XLSX from "xlsx";
import ReportPage from "../../../ReportPage/ReportPage";
import excel from "../../../../public/images/Lifeguard_list_template.xlsx";

const CssTextField = styled(TextField)({
    '& input': {
        color: "white",
    }, '& label.Mui-focused': {
        color: '#3D5484',
    }, '& .MuiInput-underline:after': {
        borderBottomColor: '#3D5484',
    }, '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#3D5484',
        }, '&:hover fieldset': {
            borderColor: '#3D5484',
        }, '&.Mui-focused fieldset': {
            borderColor: '#3D5484', borderWidth: '1px',
        }, '&.Mui-error': {
            '&:hover fieldset': {
                borderColor: "#d32f2f"
            }
        },
    },

});
const LifeguardAccordion = props => {
    const [lifeguard, setLifeguard] = useState({name: "", lastName: "", acronym: "", email: "", phone: "", pin: ""})
    const [errors, setErrors] = useState(null)
    const [expanded, setExpanded] = useState(false)
    const [t] = useTranslation()
    const [errorPage, setErrorPage] = useState(null)

    const validateLifeguard = (lifeguard) => {
        const pinCodeRegex = new RegExp(/^\d{3}$/)
        const namesRegex = new RegExp(/^([\p{L}0-9]+(?:[ -]?[\p{L}0-9]+)*){2,50}$/u)
        const acronymRegex = new RegExp(/^[\p{L}0-9]{2}$/u)
        let errors = {}
        let keys = Object.keys(lifeguard)
        keys.forEach((key) => {
            switch (key) {
                case "name":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!namesRegex.test(lifeguard[key])) {
                        errors[key] = t('invalid')
                    }
                    break;
                case "lastName":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!namesRegex.test(lifeguard[key])) {
                        errors[key] = t('invalid')

                    }
                    break;
                case "pin":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!pinCodeRegex.test(lifeguard[key])) {
                        errors[key] = t('invalid')
                    } else if (props.doesPinCodeExist(lifeguard[key])) {
                        errors[key] = t('duplicated')
                    } else if (lifeguard[key] === "555") {
                        errors[key] = t('invalid')
                    }
                    break;
                case "email":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!EmailValidator.validate(lifeguard[key])) {
                        errors[key] = t('invalid')
                    } else if (props.doesEmailExist(lifeguard[key])) {
                        errors[key] = t('duplicated')
                    }
                    break;
                case "acronym":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!acronymRegex.test(lifeguard[key])) {
                        errors[key] = t('invalid')
                    }
                    break;
                case "phone":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else {
                        let phoneNumber = parsePhoneNumber(lifeguard[key])
                        if (phoneNumber && !phoneNumber.isValid()) {
                            errors[key] = t('invalid')
                        } else if (!phoneNumber) {
                            errors[key] = t('invalid')
                        } else if (props.doesPhoneNumberExist(lifeguard[key])) {
                            errors[key] = t('duplicated')
                        }
                    }
                    break;
                default:
                    break;
            }
        })
        return errors
    }
    const handleLifeguardInput = (key, newVal) => {
        let temp = {...lifeguard}
        temp[key] = newVal
        setLifeguard(temp)
    }

    const handleCreateLifeguard = async () => {
        let context = getNodeContext()
        let lifeguardCreate = {...lifeguard}
        lifeguardCreate['phone'] = clearSpaces(lifeguardCreate['phone'])
        let validationResult = validateLifeguard(lifeguardCreate)
        if (Object.keys(validationResult).length) {
            setErrors(validationResult)
            setExpanded(true)
            return
        }
        let res = await createLifeguard(context.siteId, lifeguardCreate)
        if (res !== 201) {
            showSnackbar(t('errorCreatingLifeguard'), "error", 5000)

            setExpanded(true)
            return
        }
        showSnackbar(t('lifeguardCreated'), "success", 5000)
        props.reload()
        setExpanded(false)
        props.handleChange({}, false)
        setLifeguard({name: "", lastName: "", acronym: "", email: "", phone: "", pin: ""})
    }
    const getError = (key) => {
        return errors && JSON.stringify(errors) !== "{}" ? errors[key] : ""
    }
    const clearError = (key) => {
        if (errors) {
            if (errors[key]) {
                let temp = {...errors}
                delete temp[key]
                setErrors(temp)
            }
        }
    }
    const onAcronymChange = (event) => {
        let val = event.target.value.toUpperCase().replace(/[^\p{L}0-9]/u, "")
        handleLifeguardInput("acronym", val)
    }
    const onPINChange = (event) => {
        let val = event.target.value.replace(/[^0-9]/gi, "")
        handleLifeguardInput("pin", val)
    }

    const onPhoneChange = (value) => {
        handleLifeguardInput("phone", value)
    }

    const onAccordionChange = (event, expanded) => {
        setExpanded(expanded)
        props.handleChange(event, expanded)
        setErrors({})
    }

    function downloadExcelTemplate() {
        let anchor = document.createElement('a');
        anchor.href = excel;
        anchor.download = 'Lifeguard_list_template.xlsx';
        anchor.target = '_blank';
        anchor.click();
    }

    function checkValidityOfLGData(excelData) {
        const pinCodeRegex = new RegExp(/^(?!555)\d{3}$/)
        const namesRegex = new RegExp(/^([\p{L}0-9]+(?:[ -]?[\p{L}0-9]+)*){1,50}$/u)
        const emailRegex = new RegExp(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)
        let errors = []
        let keys = Object.keys(excelData[0])
        let isValid = true
        const countryCode = getOrgCountry()
        const phoneZipCode = getCountryPrefix(countryCode)
        excelData.forEach((lg) => {
            keys.forEach((key) => {
                switch (key) {
                    case "First Name":
                        if (!lg[key]) {
                            errors.push({field: key, index: lg['Index'], error: t('required') + ' ' + key})
                            isValid = false
                        } else if (!namesRegex.test(lg[key])) {
                            errors.push({field: key, index: lg['Index'], error: t('invalid') + ' ' + key})
                            isValid = false
                        }
                        break;
                    case "Last Name":
                        if (!lg[key]) {
                            errors.push({field: key, index: lg['Index'], error: t('required') + ' ' + key})
                            isValid = false
                        } else if (!namesRegex.test(lg[key])) {
                            errors.push({field: key, index: lg['Index'], error: t('invalid') + ' ' + key})
                            isValid = false
                        }
                        break;
                    case "Pin Code":
                        if (!lg[key]) {
                            errors.push({field: key, index: lg['Index'], error: t('required') + ' ' + key})
                            isValid = false
                        } else if (!pinCodeRegex.test(lg[key])) {
                            errors.push({field: key, index: lg['Index'], error: t('invalid') + ' ' + key})
                            isValid = false
                        } else if (props.doesPinCodeExist(lg[key])) {
                            errors.push({field: key, index: lg['Index'], error: t('duplicated') + ' ' + key})
                            isValid = false
                        } else if (lg[key] === "555") {
                            errors.push({field: key, index: lg['Index'], error: t('invalid') + ' ' + key})
                            isValid = false
                        }
                        break;
                    case "Email":
                        if (!lg[key]) {
                            errors.push({field: key, index: lg['Index'], error: t('required') + ' ' + key})
                            isValid = false
                        } else if (!EmailValidator.validate(lg[key])) {
                            errors.push({field: key, index: lg['Index'], error: t('invalid') + ' ' + key})
                            isValid = false
                        } else if (!emailRegex.test(lg[key])) {
                            errors.push({field: key, index: lg['Index'], error: t('invalid') + ' ' + key})
                            isValid = false
                        }
                        break;
                    case "Phone Number":
                        if (!lg[key]) {
                            errors.push({field: key, index: lg['Index'], error: t('required') + ' ' + key})
                            isValid = false
                        } else if (!parsePhoneNumber('+' + phoneZipCode + lg[key])) {
                            errors.push({field: key, index: lg['Index'], error: t('invalid') + ' ' + key})
                            isValid = false
                        }
                }
            })


        })
        return {isValid, errors}
    }

    function handleUploadExcel(ev) {
        try {
            const _file = ev.target.files[0]
            if (!_file) {
                alert("No file selected")
                return
            }
            if (_file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                alert("Invalid file type")
                return
            }
            debugger
            if (_file) {
                console.log("Nathan => _file", _file)
            }

            const reader = new FileReader();
            reader.onload = (e) => {
                function filterEmptyRows(arr) {
                    return arr['First Name'] || arr['Last Name'] || arr['Email'] || arr['Phone Number'] || arr['Pin Code']
                }

                function filterDuplicates(arr) {
                    return arr.filter((v, i, a) => a.findIndex(t => (t['Email'] === v['Email'] && t['Phone Number'] === v['Phone Number'])) === i)
                }
                const data = new Uint8Array(e.target.result);
                const workbook = XLSX.read(data, {type: 'array'});
                if (workbook?.Sheets && workbook?.Sheets['Lifeguards'] && workbook?.Sheets['Lifeguards']['G1']?.v !== '123456qwerfשדגכ') {
                    alert("Invalid or corrupted file")
                    return
                }
                const sheet = workbook.SheetNames[0];
                let xlData = XLSX.utils.sheet_to_json(workbook.Sheets[sheet]);
                if ( xlData[1]['__EMPTY'] !== 'First Name'
                    || xlData[1]['__EMPTY_1'] !== 'Last Name'
                    || xlData[1]['__EMPTY_2'] !== 'Email'
                    || xlData[1]['__EMPTY_3'] !== 'Phone Number'
                    || xlData[1]['__EMPTY_4'] !== 'Pin Code') {
                    alert("Invalid or corrupted file")
                    return
                }
                const isTableStructureCorrupted = xlData.slice(0, xlData.length - 1).some(lgRow => {
                    return !lgRow.hasOwnProperty('Lifeguard List Template')
                })
                if (isTableStructureCorrupted || !xlData[101] || xlData[101]['__EMPTY'] !== '123456qwerfשדגכ') {
                    alert("Invalid or corrupted file")
                    return
                }
                const headers = Object.values(xlData[1])
                let excelData = xlData.slice(2,xlData.length-1).map(lgRow => {
                    return {
                        [headers[0]]: lgRow['Lifeguard List Template'],//index
                        [headers[1]]: lgRow['__EMPTY'] || null,//name
                        [headers[2]]: lgRow['__EMPTY_1'] || null,//lastName
                        [headers[3]]: lgRow['__EMPTY_2'] || null,//email
                        [headers[4]]: lgRow['__EMPTY_3'] || null,//phone
                        [headers[5]]: lgRow['__EMPTY_4'] || null//pin
                    }
                })

                excelData = excelData.filter(filterEmptyRows)
                excelData = filterDuplicates(excelData)
                const validation = checkValidityOfLGData(excelData)
                const invalidRecordIndex = Array.from(new Set(validation.errors.map(e => e.index)))
                const validationRecords = excelData.filter((e) => !invalidRecordIndex.includes(e['Index']))
                console.log("Nathan => validationRecord", validationRecords)
                const allErrors = [...validation.errors]
                if (validationRecords?.length) {
                    createBulkLifeguardViaExcel(getNodeContext().siteId, validationRecords).then((res) => {
                        if (res.status === 201 && res.data.errors.length + validation.errors.length > 0) {
                            allErrors.push(...res.data.errors)
                            console.log("Nathan => allErr", allErrors)
                            setErrorPage(allErrors)
                        }
                        showSnackbar(t('processFinished'), "success", 5000)
                        props.reload()
                    }).catch((e) => {
                        showSnackbar(t('errorCreatingLifeguard'), "error", 5000)
                    })
                }
            }


            console.log("Nathan => _file", _file)
            reader.readAsArrayBuffer(_file);
            //clear the input
            const input = document.getElementById('file');
            input.value = '';
        } catch (e) {
            console.log("Nathan => e", e)
        }

    }

    function handleFileChange(ev) {
        const input = document.getElementById('file');
        input.click();
        input.onchange = handleUploadExcel;
    }

    function closeReportsModal(event, reason) {
        if (reason && reason === "backdropClick") return;
        setErrorPage(null)
    }

    return (<div className={'lifeguard-creation-pane'}>
        <Modal open={!!errorPage}
               onClose={() => setErrorPage(null)}
               style={{display: "flex", alignItems: "center", justifyContent: "center"}}
        >
            <ReportPage close={closeReportsModal} errors={errorPage || []} title={t('uploadCompleted')}/>
        </Modal>
        <Accordion expanded={expanded} onChange={onAccordionChange} elevation={6}
                   sx={{minHeight: "1px", height: "fit-content"}}>
            <AccordionSummary expandIcon={expanded ? <RemoveIcon sx={{color: "#EAFBFF"}}/> :
                <AddIcon sx={{color: "#EAFBFF"}}/>}>{t('addLifeguard')}</AccordionSummary>
            <AccordionDetails>
                <div className={'lifeguard-pane'}>
                    <div className={'text-fields-row'}>
                        <CssTextField onFocus={() => {
                            clearError("name")
                        }}
                                      error={getError("name")}
                                      helperText={getError("name")}
                                      inputProps={{maxLength: 50}}
                                      value={lifeguard.name}
                                      onChange={(event) => {
                                          handleLifeguardInput("name", event.target.value)
                                      }}
                                      style={{width: "53%"}}
                                      label={t('firstName')}
                                      size={'small'}></CssTextField>

                        <CssTextField onFocus={() => {
                            clearError("lastName")
                        }}
                                      inputProps={{maxLength: 50}}
                                      error={getError("lastName")}
                                      helperText={getError("lastName")}
                                      value={lifeguard.lastName}
                                      onChange={(event) => {
                                          handleLifeguardInput("lastName", event.target.value)
                                      }}
                                      style={{width: "53%"}}
                                      label={t('lastName')}
                                      size={'small'}></CssTextField>

                        <CssTextField inputProps={{maxLength: 2}}
                                      onFocus={() => {
                                          clearError("acronym")
                                      }}
                                      error={getError("acronym")}
                                      helperText={getError("acronym")}
                                      value={lifeguard.acronym}
                                      onChange={onAcronymChange}
                                      label={t('acronym')}
                                      size={'small'}
                                      style={{width: "140px"}}></CssTextField>
                    </div>
                    <div className={'text-fields-row'}>
                        <CssTextField onFocus={() => {
                            clearError("email")
                        }}
                                      inputProps={{maxLength: 320}}

                                      error={getError("email")}
                                      helperText={getError("email")}
                                      value={lifeguard.email}
                                      onChange={(event) => {
                                          handleLifeguardInput("email", event.target.value)
                                      }}
                                      style={{width: "53%"}}
                                      label={t('email')}
                                      size={'small'}></CssTextField>
                        <CustomPhoneInput onFocus={() => {
                            clearError("phone")
                        }}
                                          error={getError("phone")}
                                          defaultCountry={getOrgCountry()}
                                          value={lifeguard.phone}
                                          onChange={onPhoneChange}
                                          style={{width: "53%", height: "40px"}}/>
                        <CssTextField inputProps={{maxLength: 3}}
                                      onFocus={() => {
                                          clearError("pin")
                                      }}
                                      error={getError("pin")}
                                      helperText={getError("pin")}
                                      value={lifeguard.pin}
                                      onChange={onPINChange}
                                      label={t('pinCode')}
                                      size={'small'}
                                      sx={{width: "140px"}}></CssTextField>
                    </div>
                </div>

            </AccordionDetails>
            <AccordionActions>
                <div className={`actions-row ${process.env.REACT_APP_LIFEGUARD_BULK_UPLOAD !=='GO'?'flexEnd':''}`}>
                    {process.env.REACT_APP_LIFEGUARD_BULK_UPLOAD==='GO' &&  <div className={'couple'}>
                        <div>
                            <input type="file" id="file" accept={'.xlsx'} style={{display: "none"}}/>
                            <Button size="small"
                                    onClick={handleFileChange}
                                    sx={{fontWeight: "1000"}}
                                    style={{
                                        backgroundColor: "#57A6DB",
                                        color: "#00173D",
                                        fontSize: "14px",
                                        fontFamily: "Work Sans",
                                        textTransform: "none",
                                        padding: "5px 15px"
                                    }}>
                                {t('uploadFile')}
                            </Button>
                        </div>
                        <Button size="small"
                                onClick={downloadExcelTemplate}
                                sx={{fontWeight: "1000"}}
                                style={{
                                    backgroundColor: "#57A6DB",
                                    color: "#00173D",
                                    fontSize: "14px",
                                    fontFamily: "Work Sans",
                                    textTransform: "none",
                                    padding: "5px 15px"
                                }}>
                            {t('downloadTemplate')}
                        </Button>
                    </div>}

                   <div className={'couple'}>
                        <Button onClick={() => {
                            setLifeguard({name: "", lastName: "", acronym: "", email: "", phone: "", pin: ""});
                            setErrors({})
                        }}
                                size="small"
                                style={{
                                    color: "#EAFBFF", fontSize: "14px", fontFamily: "Work Sans", textTransform: "none"
                                }}
                                sx={{fontWeight: "700"}}>
                            {t('clear')}
                        </Button>
                        <Button onClick={() => {
                            handleCreateLifeguard()
                        }}
                                size="small"
                                sx={{fontWeight: "1000"}}
                                style={{
                                    backgroundColor: "#57A6DB",
                                    color: "#00173D",
                                    fontSize: "14px",
                                    fontFamily: "Work Sans",
                                    textTransform: "none",
                                    padding: "5px 15px"
                                }}>
                            {t('addLifeguard')}
                        </Button>
                    </div>
                </div>

            </AccordionActions>
        </Accordion>
    </div>)
}
LifeguardAccordion.propTypes = {
    handleChange: PropTypes.func,
    doesPinCodeExist: PropTypes.func.isRequired,
    reload: PropTypes.func.isRequired,
    doesPhoneNumberExist: PropTypes.func.isRequired,
    doesEmailExist: PropTypes.func.isRequired
}
export default LifeguardAccordion


