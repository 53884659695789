import React from 'react';
import PropTypes from 'prop-types';
import './LineTile.scss';
import {capitalize} from "@mui/material";

const LineTile = ({data, fields, header}) => {
    return <div id={header?'line-tile-header':'line-tile'}>
        {header ? fields.map((field, index) => (<div key={index} className="tile-field-header">
                {capitalize( field)}
            </div>))
            :
            fields.map((field, index) => (<div key={index} className="tile-field">
                {data[field] || 'N/A'}
            </div>))
        }
    </div>

}

LineTile.propTypes = {
    data: PropTypes.object.isRequired, fields: PropTypes.arrayOf(PropTypes.string).isRequired, header: PropTypes.bool,
};

export default LineTile;
