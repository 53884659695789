import './Dashboard.scss'
import Tabs from "../Tabs/Tabs";
import {useEffect, useState} from "react";
import Header from "../Header/Header";
import {ReactComponent as LiveIcon} from './live-icon.svg'
import {ReactComponent as HistoryIcon} from './history-icon.svg'
import SwimmersCountHistoryTile from "../SwimmersCountHistoryTile/SwimmersCountHistoryTile";
import SafetyEventsTile from "../SafetyEventsTile/SafetyEventsTile";
import LifeguardReportsTile from "../LifeguardReportsTile/LifeguardReportsTile";
import moment from "moment";
import {useTranslation} from "react-i18next";
import SimpleDatePicker from "../SimpleDatePicker/SimpleDatePicker";
import Shelf from '../Shelf/Shelf';
import {useNavigate} from 'react-router-dom';
import LiveSection from "../LiveSection/LiveSection";
import mixpanel from 'mixpanel-browser';
import {
    fetchSiteLifeguardReports,
    fetchSiteLiveData,
    fetchSiteSafetyEvents,
    fetchSiteSwimmerHistory
} from "../../api/api";
import {getNodeContext, getStartEndDates} from "../../utils/utils";


const Dashboard = () => {
    const [liveData, setLiveData] = useState([])
    const [watches, setWatches] = useState([])
    const [lifeguardReportsData, setLifeguardReportsData] = useState([])
    const [safetyReportsData, setSafetyReportsData] = useState([])
    const [historyData, setHistoryData] = useState({data: []})
    const [selectedTab, setSelectedTab] = useState(0)
    const [timeFrame, setTimeFrame] = useState({start: moment().startOf('day'), end: moment().endOf('day')})
    const [t] = useTranslation()
    const navigate = useNavigate();
    const [adminMode, setAdminMode] = useState(false)
    const [shelfState, setShelfState] = useState(false);
    const [shelfHeader, setShelfHeader] = useState({poolName: '', poolId: 0, shelfType: -1});
    const [shelfTransformation, setShelfTransformation] = useState([])
    mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, { debug: true, track_pageview: true, persistence: 'localStorage' });

    const getToken = () => {
        return getNodeContext().token
    }
    const updateSelectedTab = (tab) => {
        setSelectedTab(tab)
        setShelfTransformation(liveData[liveData.length === 2 ? 1 : tab].content.transformation)
        const {start, end} = getStartEndDates(timeFrame)
        const siteId = getNodeContext().siteId
        if (timeFrame.start.date() === timeFrame.end.date() && timeFrame.start.date() === moment().date()) {
            getAllData({start, end}, siteId)
        } else {
            fetchSiteLiveData(siteId).then((_liveData) => {
                if (!_liveData.length) return
                setLiveData(_liveData);
            }).catch(ex => {
                console.log("Error fetching live data in updateSelectedTab function: ", ex)
            })
        }
    }
    const fetchHistoryTileData = ({start, end}, siteId) => {
        Promise.all([
            fetchSiteSwimmerHistory(siteId, start, end), fetchSiteSafetyEvents(siteId, start, end), fetchSiteLifeguardReports(siteId, start, end)
        ]).then(([_historyData, _safetyData, _lifeguardData]) => {
            if (_lifeguardData.length)
                setLifeguardReportsData(_lifeguardData)
            if (_historyData.length)
                setHistoryData(_historyData)
            if (_safetyData.length)
                setSafetyReportsData(_safetyData)
        }).catch(ex => {
            console.log("Error fetching data: ", ex)
        })
    }
    const getAllData = ({start, end}, siteId) => {
        Promise.all([
            fetchSiteLiveData(siteId),
            fetchSiteLifeguardReports(siteId, start, end),
            fetchSiteSwimmerHistory(siteId, start, end),
            fetchSiteSafetyEvents(siteId, start, end)
        ]).then(([
                     _liveData,
                     _lifeguardData,
                     _historyData,
                     _safetyData
                 ]) => {
            console.log("Fetched Data =>>  _liveData, _lifeguardData, _historyData, _safetyData", _liveData, _lifeguardData, _historyData, _safetyData);
            if (_liveData.length) {
                const watches = _liveData.filter((e) => e.id !== -1).map((e) => {
                    return {id: e.id, watches: e.content.activeWatches}
                })
                setWatches(watches)
                setLiveData(_liveData)
                setShelfTransformation(_liveData[_liveData.length === 2 ? 1 : selectedTab].content.transformation)
            }


            if (_lifeguardData.length)
                setLifeguardReportsData(_lifeguardData)
            if (_historyData.length) {
                setHistoryData(_historyData)
            }
            if (_safetyData.length)
                setSafetyReportsData(_safetyData)

        }).catch(ex => {
            console.log("Error fetching live data in useEffect interval: ", ex)
        })
    }
    useEffect(() => {
        if (!window.location.pathname || window.location.pathname === '/'){
            navigate('/dashboard')
        }
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const tabSelected = Number(urlParams.get('tab'))
        setSelectedTab(tabSelected)
        const {start, end} = getStartEndDates({start: moment().startOf('day'), end: moment().endOf('day')})
        const context = getNodeContext() || {}
        const siteId = context.siteId
        getAllData({start, end}, siteId)
        mixpanel.track_pageview({"page": "Dashboard"});
        const intervalId = setInterval(() => {
            fetchSiteLiveData(siteId).then((_liveData) => {
                if (!_liveData.length) return
                setLiveData(_liveData);
            }).catch(ex => {
                console.log("Error fetching live data in updateSelectedTab function: ", ex)
            })
        },12*10*1000)
        return () => {
            clearInterval(intervalId)
        }
    }, [])

    const handleSetTimeFrame = (value) => {
        const context = getNodeContext() || {}
        const siteId = context.siteId
        fetchHistoryTileData(getStartEndDates(value), siteId)
        setTimeFrame(value)
    }

    const handleShelf = (_state, _poolName, _shelfType, _poolId) => {
        setShelfState(_state)
        if (!_state) return
        setShelfHeader({poolName: _poolName, poolId: _poolId, shelfType: _shelfType});
        setShelfTransformation(liveData[liveData.length === 2 ? 1 : selectedTab].content.transformation)
    }

    const renderPoolView = (dataArr) => {
        dataArr.forEach((pool, index) => {
            if (!pool.general) {
                pool.pane =
                    <div className={'main-pane'}
                         key={pool.dashboard_name + pool.content.riskLevel + Math.random()}>
                        <div className={'pane '}>
                            <div className={'inner-pane max270'}>
                                <div className={'title-container'}>
                                    <div className={'title-inner-con'}>
                                        <LiveIcon className={'icon-svg'}/>
                                        <div className={'title'}>{t('live')}</div>
                                    </div>
                                </div>
                                <LiveSection adminMode={adminMode} isGeneral={false} selectedTab={selectedTab}
                                             data={pool}
                                             handleShelf={handleShelf}/>
                            </div>
                        </div>
                        <div className={'pane'}>
                            <div className={'inner-pane height100'}>
                                <div className={'title-container'}>
                                    <div className={'title-inner-con'}>
                                        <HistoryIcon className={'icon-svg'}/>
                                        <div className={'title'}>{t('history')}</div>
                                    </div>
                                    <SimpleDatePicker timeFrame={timeFrame} setTimeFrame={handleSetTimeFrame}/>
                                </div>
                                <SwimmersCountHistoryTile timeFrame={timeFrame} data={historyData[index] || {}}
                                                          openShelf={handleShelf} poolName={pool.dashboard_name}
                                                          poolId={pool.id} isDrillDownActive={true}/>
                                <SafetyEventsTile timeFrame={timeFrame} data={safetyReportsData[index]}
                                                  openShelf={handleShelf} poolName={pool.dashboard_name}
                                                  poolId={pool.id} isDrillDownActive={true}/>
                                <LifeguardReportsTile timeFrame={timeFrame} data={lifeguardReportsData[index]|| []}
                                                      openShelf={handleShelf} poolName={pool.dashboard_name}
                                                      poolId={pool.id} isDrillDownActive={true}/>
                            </div>
                        </div>
                    </div>
            } else {
                pool.pane = <div className={'main-pane'} key={'key-' + Math.random()}>
                    <div className={'pane full-height'}>
                        <div className={`inner-pane  top-container `}>
                            <LiveSection isGeneral={true}
                                         getToken={getToken}
                                         completeData={dataArr}
                                         data={pool}
                                         handleShelf={handleShelf}/>
                            <div className={'history-pane'}>
                                <div className={'inner-pane-history-pane'}>
                                    <div className={'title-container-general'}>
                                        <div className={'title-inner-container'}>
                                            <HistoryIcon className={'icon-svg'}/>
                                            <div className={'title'}>{t('history')}</div>
                                        </div>
                                        <SimpleDatePicker timeFrame={timeFrame} setTimeFrame={handleSetTimeFrame}/>
                                    </div>
                                    <SwimmersCountHistoryTile timeFrame={timeFrame} data={historyData[index] || {}}
                                                              openShelf={handleShelf} poolName={pool.dashboard_name}
                                                              poolId={pool.id} isDrillDownActive={false}/>
                                    <SafetyEventsTile timeFrame={timeFrame}
                                                      data={safetyReportsData.find(element => element.id === -1)}
                                                      openShelf={handleShelf} poolName={pool.dashboard_name}
                                                      poolId={pool.id} isDrillDownActive={true}/>
                                    <LifeguardReportsTile timeFrame={timeFrame}
                                                          data={[]}
                                                          allPoolsView={true}
                                                          allPoolsData={lifeguardReportsData}
                                                          openShelf={handleShelf}
                                                          poolName={pool.dashboard_name}
                                                          poolId={pool.id}
                                                          isDrillDownActive={true}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        })
        return dataArr || []
    }


    const handleAdminMode = () => {
        setAdminMode(!adminMode)
    }
    return <div id={'dashboard-page'}>
        <Header adminMode={adminMode} setAdminMode={handleAdminMode} context={getNodeContext()}/>
        <Tabs children={renderPoolView(liveData)} selected={selectedTab} updateSelectedTab={updateSelectedTab}/>
        <Shelf transformation={shelfTransformation || [[], [], []]} watches={watches} header={shelfHeader}
               isOpen={shelfState}
               timeFrame={timeFrame} handleCloseShelf={handleShelf}/>
    </div>
}
export default Dashboard