import './LifeguardItem.scss'
import * as React from 'react';
import {useState} from 'react';
import PropTypes from "prop-types";
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {Button, Divider, IconButton, Menu, MenuItem, styled, TextField} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {deleteLifeguard, patchLifeguard} from "../../../../../api/api";
import * as EmailValidator from 'email-validator';
import parsePhoneNumber from 'libphonenumber-js'
import CustomPhoneInput from "../../../../CustomPhoneInput/CustomPhoneInput";
import {clearSpaces, getNodeContext, getOrgCountry, removeUnchanged} from "../../../../../utils/utils";
import {showSnackbar} from "../../../../../utils/SnackbarUtils";
import {useTranslation} from "react-i18next";
import BadgeIcon from "./BadgeIcon.png";
const CssTextField = styled(TextField)({
    '& input': {
        color: "white",
    }, '& label.Mui-focused': {
        color: '#3D5484',
    }, '& .MuiInput-underline:after': {
        borderBottomColor: '#3D5484',
    }, '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#3D5484',
        }, '&:hover fieldset': {
            borderColor: '#3D5484',
        }, '&.Mui-focused fieldset': {
            borderColor: '#3D5484', borderWidth: '1px',
        }, '&.Mui-error': {
            '&:hover fieldset': {
                borderColor: "#d32f2f"
            }
        },
    },

});
const LifeguardItem = props => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [editDialogOpen, setEditDialogOpen] = useState(false)
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
    const [lifeguard, setLifeguard] = useState(props.lifeguard)
    const [errors, setErrors] = useState(null)
    const isAdmin = getNodeContext().permissions === 'administrator'
    const [t] = useTranslation()
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleEdit = () => {
        setErrors({})
        setLifeguard(props.lifeguard)
        setEditDialogOpen(true)
        setAnchorEl(null)
    }
    const handleDelete = () => {
        setDeleteDialogOpen(true)
        setAnchorEl(null)
    }
    const handleLifeguardEdit = (key, newVal) => {
        let temp = {...lifeguard}
        temp[key] = newVal
        setLifeguard(temp)
    }

    const validateLifeguard = (lifeguard) => {
        const pinCodeRegex = new RegExp(/^\d{3}$/);
        const namesRegex = new RegExp(/^([\p{L}0-9]+(?:[ -]?[\p{L}0-9]+)*){2,50}$/u)
        const acronymRegex = new RegExp(/^[\p{L}0-9]{2}$/u)
        let errors = {}
        let keys = Object.keys(lifeguard)
        keys.forEach((key) => {
            switch (key) {
                case "name":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!namesRegex.test(lifeguard[key])) {
                        errors[key] = t('invalid')

                    }
                    break;
                case "lastName":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!namesRegex.test(lifeguard[key])) {
                        errors[key] = t('invalid')
                    }
                    break;
                case "pin":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!pinCodeRegex.test(lifeguard[key])) {
                        errors[key] = t('invalid')
                    } else if (lifeguard[key] !== props.lifeguard[key]) {
                        if (props.doesPinCodeExist(lifeguard[key])) {
                            errors[key] = t('duplicated')
                        }
                    } else if (lifeguard[key] === "555") {
                        errors[key] = t('invalid')
                    }
                    break;
                case "email":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!EmailValidator.validate(lifeguard[key])) {
                        errors[key] = t('invalid')
                    } else if (lifeguard[key] !== props.lifeguard[key]) {
                        if (props.doesEmailExist(lifeguard[key])) {
                            errors[key] = t('duplicated')
                        }
                    }

                    break;
                case "acronym":
                    if (!lifeguard[key]) {
                        errors[key] = t('required')
                    } else if (!acronymRegex.test(lifeguard[key])) {
                        errors[key] = t('invalid')
                    }
                    break;
                case "phone":
                    if (clearSpaces(lifeguard[key]) !== clearSpaces(props.lifeguard[key])) {
                        if (!lifeguard[key]) {
                            errors[key] = t('required')
                        } else {
                            let phoneNumber = parsePhoneNumber(lifeguard[key])
                            if (phoneNumber && !phoneNumber.isValid()) {
                                errors[key] = t('invalid')
                            } else if (!phoneNumber) {
                                errors[key] = t('invalid')
                            } else if (props.doesPhoneNumberExist(lifeguard[key])) {
                                errors[key] = t('duplicated')
                            }
                        }
                    }
                    break;
                default:
                    break;
            }
        })


        return errors
    }

    const editLifeguard = () => {
        if (!props.lifeguard.id) {
            setEditDialogOpen(false)
            return
        }
        let lifeguardClone = {...lifeguard}
        lifeguardClone['phone'] = clearSpaces(lifeguardClone['phone'])
        let lifeguardEdit = removeUnchanged(props.lifeguard, lifeguardClone)
        //clearEmptyFields(lifeguardEdit)
        if (!Object.keys(lifeguardEdit).length) {
            setEditDialogOpen(false)
            return
        }
        let validationResult = validateLifeguard(lifeguardEdit)
        if (Object.keys(validationResult).length) {
            setErrors(validationResult)
            return false
        }
        patchLifeguard(props.lifeguard.id, props.lifeguard.siteId, lifeguardEdit).then((res) => {
            if (res != 200) {
                showSnackbar(t('errorEditingLifeguard'), "error", 5000)
                setEditDialogOpen(true)
                return
            }
            setEditDialogOpen(false)
            showSnackbar(t("lifeguardEdited"), "success", 5000)

            props.reload()
            //setLifeguard(props.lifeguard)
            //setErrors({})
        })

    }
    const removeLifeguard = () => {
        if (!props.lifeguard.id) {
            setDeleteDialogOpen(false)
            return
        }
        deleteLifeguard(props.lifeguard.id, props.lifeguard.siteId, props.lifeguard.pin).then((res) => {
            if (res != 200) {
                showSnackbar(t("errorRemovingLifeguard"), "error", 5000)
                return
            }
            setDeleteDialogOpen(false)
            showSnackbar(t("lifeguardRemoved"), "success", 5000)
            props.reload()
        })
    }

    const getError = (key) => {
        return errors && JSON.stringify(errors) !== "{}" ? errors[key] : ""
    }
    const clearError = (key) => {
        if (errors) {
            if (errors[key]) {
                let temp = {...errors}
                delete temp[key]
                setErrors(temp)
            }
        }
    }
    const onAcronymChange = (event) => {
        let val = event.target.value.toUpperCase().replace(/[^\p{L}0-9]/u, "")
        handleLifeguardEdit("acronym", val)
    }
    const onPINChange = (event) => {
        let val = event.target.value.replace(/[a-z]/gi, "")
        handleLifeguardEdit("pin", val)
    }

    const onPhoneChange = (value) => {
        handleLifeguardEdit("phone", value)
    }
    return (
        <div className={'lifeguard-item-container'}>
            <div className={"right-part"}>
                <Avatar sx={{
                    width: "36px",
                    height: "36px",
                    fontSize: "1.1rem",
                    bgcolor: "#001333",
                    border: "2px solid #57A6DB",
                    color: "#57A6DB",
                    fontFamily: "Work Sans"
                }}>{props.lifeguard.acronym}</Avatar>
                <span>{props.lifeguard.name} {props.lifeguard.lastName}</span>
            </div>
            <div className={"left-part"}>
                <div className={'icon-container'} >
                    {lifeguard.passedTraining &&  <img width={'30px'} height={'30px'} src={BadgeIcon} alt={"badge"}/>}
                </div>
                <span>{props.lifeguard.pin}</span>
                <IconButton disabled={!isAdmin} onClick={handleClick}>
                    <MoreVertIcon></MoreVertIcon>
                </IconButton>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        style: {
                            backgroundColor: "#001333",
                            color: "#EAFBFF",
                            border: "1px solid #3D5484",
                            display: "block"
                        }
                    }}
                >
                    <MenuItem onClick={handleEdit}>{t("edit")}</MenuItem>
                    <MenuItem onClick={handleDelete}>{t("remove")}</MenuItem>
                </Menu>
                <Dialog
                    open={editDialogOpen}
                    onClose={() => {
                        setEditDialogOpen(false);
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        style: {backgroundColor: "#001333", border: "1px solid #3D5484", borderRadius: "5px"},
                        sx: {overflowY: "unset"}
                    }}>
                    <DialogTitle id="alert-dialog-title" style={{color: "#EAFBFF",}}>
                        {t("editingLifeguardDetails")}
                    </DialogTitle>
                    <Divider sx={{color: "white", backgroundColor: "#3D5484"}}/>
                    <DialogContent sx={{overflowY: "unset"}}>
                        <div className={'lifeguard-pane'}>
                            <div className={'text-fields-row'}>
                                <CssTextField onFocus={() => {
                                    clearError("name")
                                }}
                                              inputProps={{maxLength: 50}}
                                              error={getError("name")}
                                              helperText={getError("name")}
                                              value={lifeguard.name}
                                              onChange={(event) => {
                                                  handleLifeguardEdit("name", event.target.value)
                                              }}
                                              style={{width: "60%"}}
                                              label={t('firstName')}
                                              size={'small'}></CssTextField>
                                <CssTextField onFocus={() => {
                                    clearError("lastName")
                                }}
                                              inputProps={{maxLength: 50}}

                                              error={getError("lastName")}
                                              helperText={getError("lastName")}
                                              value={lifeguard.lastName}
                                              onChange={(event) => {
                                                  handleLifeguardEdit("lastName", event.target.value)
                                              }}
                                              style={{width: "60%"}}
                                              label={t('lastName')}
                                              size={'small'}></CssTextField>
                                <CssTextField inputProps={{maxLength: 2}}
                                              onFocus={() => {
                                                  clearError("acronym")
                                              }}
                                              error={getError("acronym")}
                                              helperText={getError("acronym")}
                                              value={lifeguard.acronym}
                                              onChange={onAcronymChange}
                                              label={t('acronym')}
                                              size={'small'}
                                              style={{width: "135px"}}></CssTextField>
                            </div>
                            <div className={'text-fields-row'}>
                                <CssTextField onFocus={() => {
                                    clearError("email")
                                }}
                                              inputProps={{maxLength: 320}}

                                              error={getError("email")}
                                              helperText={getError("email")}
                                              value={lifeguard.email}
                                              onChange={(event) => {
                                                  handleLifeguardEdit("email", event.target.value)
                                              }}
                                              style={{width: "60%"}}
                                              label={t('email')}
                                              size={'small'}></CssTextField>
                                <CustomPhoneInput setValue={() => {
                                }}
                                                  onFocus={() => {
                                                      clearError("phone")
                                                  }}
                                                  error={getError("phone")}
                                                  defaultCountry={getOrgCountry()}
                                                  value={lifeguard.phone}
                                                  onChange={onPhoneChange}
                                                  style={{width: "60%", height: "40px"}}/>
                                <CssTextField inputProps={{maxLength: 3}}
                                              onFocus={() => {
                                                  clearError("pin")
                                              }}
                                              error={getError("pin")}
                                              helperText={getError("pin")}
                                              value={lifeguard.pin}
                                              onChange={onPINChange}
                                              label={t('pinCode')}
                                              size={'small'}
                                              sx={{width: "135px"}}></CssTextField>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <div className={"actions-row"}>
                            <Button onClick={() => {
                                setEditDialogOpen(false)
                            }}
                                    size="small"
                                    style={{
                                        color: "#EAFBFF",
                                        fontSize: "14px",
                                        fontFamily: "Work Sans",
                                        textTransform: "none"
                                    }}>
                                {t('cancel')}
                            </Button>
                            <Button onClick={() => {
                                editLifeguard()
                            }}
                                    size="small"
                                    style={{
                                        backgroundColor: "#57A6DB",
                                        color: "#00173D",
                                        fontSize: "14px",
                                        fontFamily: "Work Sans",
                                        textTransform: "none"
                                    }}>
                                {t('saveChanges')}
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={deleteDialogOpen}
                    onClose={() => {
                        setDeleteDialogOpen(false)
                    }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{
                        sx: {
                            backgroundColor: "#001333",
                            color: "#EAFBFF",
                            border: "1px solid #3D5484",
                            padding: "28px 24px 28px 24px",
                            borderRadius: "5px"
                        }
                    }}
                >
                    <DialogTitle id="alert-dialog-title">
                        <div style={{textAlign: "center"}}>
                            {t("areYouSureYouWantToDeleteTheLifeguard")}
                            <br/>
                            <b>{props.lifeguard.name} {props.lifeguard.lastName}?</b>
                        </div>

                    </DialogTitle>
                    <DialogActions>
                        <div className={"delete-actions-row"}>
                            <Button onClick={() => {
                                setDeleteDialogOpen(false)
                            }}
                                    size="small"
                                    style={{
                                        color: "#EAFBFF",
                                        fontSize: "14px",
                                        fontFamily: "Work Sans",
                                        textTransform: "none"
                                    }}>
                                <b>{t('cancel')}</b>
                            </Button>
                            <Button onClick={() => {
                                removeLifeguard()
                            }}
                                    variant="outlined"
                                    style={{
                                        color: "#FF4D4D",
                                        borderColor: "#FF4D4D",
                                        fontSize: "14px",
                                        fontFamily: "Work Sans",
                                        textTransform: "none"
                                    }}>
                                <b>{t('delete')}</b>
                            </Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </div>
        </div>

    )
}
LifeguardItem.propTypes = {
    lifeguard: PropTypes.object.isRequired,
    reload: PropTypes.func.isRequired,
    doesPinCodeExist: PropTypes.func.isRequired,
    doesPhoneNumberExist: PropTypes.func.isRequired,
    doesEmailExist: PropTypes.func.isRequired
}
export default LifeguardItem


