import './ReportPage.scss'
import PropTypes from "prop-types";
import {Button} from "@mui/material";
import LineTile from "../LineTile/LineTile";
import {useTranslation} from "react-i18next";

const fields = ['index', 'field', 'error',]
const ReportPage = ({title, close, errors}) => {
    const [t] = useTranslation()

    function renderErrors() {
        if (errors.length === 0) return <></>
        return (errors.sort((a, b) => a.index - b.index).map((error, index) => <div key={'key-' + index} className={'error'}>
            <LineTile data={error} fields={fields}/>
        </div>))
    }

    return (<div id='report-page'>
        <div className={'report-title'}>{title}</div>
        {errors.length ? <>
            <div className={'title2'}>{t('encounteredIssues')}</div>
            <LineTile header data={[]} fields={fields}/>
            <div className={'errors-container'}>
                {renderErrors()}
            </div>
        </> : <div className={'container-centered'}>
            <div className={'title1'}>{t('noIssuesEncountered')}</div>
        </div>}

        <div className={'report-footer'}>
            <Button variant={'contained'} onClick={close}>Close</Button>
        </div>
    </div>)
}
ReportPage.propTypes = {
    errors: PropTypes.array.isRequired, title: PropTypes.string.isRequired, close: PropTypes.func.isRequired,
}

export default ReportPage